/* @import url(font-awesome.min.css); */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300italic,600italic,300,600");
/*
	Dimension by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/
/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Basic */
@-ms-viewport {
  width: device-width;
}
@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}
body {
  background: #1b1f22;
}
body.is-loading *,
body.is-loading *:before,
body.is-loading *:after,
body.is-switching *,
body.is-switching *:before,
body.is-switching *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  -moz-transition-delay: none !important;
  -webkit-transition-delay: none !important;
  -ms-transition-delay: none !important;
  transition-delay: none !important;
}

/* Type */
html {
  font-size: 12pt;
}
/* @media screen and (max-width: 1680px) {
  html {
    font-size: 12pt;
  }
} */
@media screen and (max-width: 736px) {
  html {
    font-size: 11pt;
  }
}
@media screen and (max-width: 360px) {
  html {
    font-size: 10pt;
  }
}

body,
input,
select,
textarea {
  color: #ffffff;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.65;
}

a {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px rgba(255, 255, 255, 0.5);
  text-decoration: none;
  color: inherit;
}
a:hover {
  border-bottom-color: transparent;
}

strong,
b {
  color: #ffffff;
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}
h1.major,
h2.major,
h3.major,
h4.major,
h5.major,
h6.major {
  border-bottom: solid 1px #ffffff;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -ms-max-content;
  width: max-content;
  padding-bottom: 0.5rem;
  margin: 0 0 2rem 0;
}

h1 {
  font-size: 2.25rem;
  line-height: 1.3;
  letter-spacing: 0.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 1.4;
  letter-spacing: 0.5rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.7rem;
}

h6 {
  font-size: 0.6rem;
}

@media screen and (max-width: 736px) {
  h1 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  h2 {
    font-size: 1.25em;
    line-height: 1.5;
  }
}
sub {
  font-size: 0.8rem;
  position: relative;
  top: 0.5rem;
}

sup {
  font-size: 0.8rem;
  position: relative;
  top: -0.5rem;
}

blockquote {
  border-left: solid 4px #ffffff;
  font-style: italic;
  margin: 0 0 2rem 0;
  padding: 0.5rem 0 0.5rem 2rem;
}

code {
  background: rgba(255, 255, 255, 0.075);
  border-radius: 4px;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0.25rem;
  padding: 0.25rem 0.65rem;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9rem;
  margin: 0 0 2rem 0;
}
pre code {
  display: block;
  line-height: 1.75;
  padding: 1rem 1.5rem;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px #ffffff;
  margin: 2.75rem 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Form */
form {
  margin: 0 0 2.5rem 0;
}
form .field {
  margin: 0 0 1.5rem 0;
}
form .field.half {
  width: 50%;
  float: left;
  padding: 0 0 0 0.75rem;
}
form .field.half.first {
  padding: 0 0.75rem 0 0;
}
form > .actions {
  margin: 1.875rem 0 0 0 !important;
}
@media screen and (max-width: 736px) {
  form .field {
    margin: 0 0 1.125rem 0;
  }
  form .field.half {
    padding: 0 0 0 0.5625rem;
  }
  form .field.half.first {
    padding: 0 0.5625rem 0 0;
  }
  form > .actions {
    margin: 1.5rem 0 0 0 !important;
  }
}
@media screen and (max-width: 480px) {
  form .field.half {
    width: 100%;
    float: none;
    padding: 0;
  }
  form .field.half.first {
    padding: 0;
  }
}

label {
  color: #ffffff;
  display: block;
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.5;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -webkit-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  background: transparent;
  border-radius: 4px;
  border: solid 1px #ffffff;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1rem;
  text-decoration: none;
  width: 100%;
}
input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
  background: rgba(255, 255, 255, 0.075);
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}

select option {
  background: #1b1f22;
  color: #ffffff;
}

.select-wrapper {
  text-decoration: none;
  display: block;
  position: relative;
}
.select-wrapper:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.select-wrapper:before {
  color: #ffffff;
  content: "\f107";
  display: block;
  height: 2.75rem;
  line-height: calc(2.75rem + 0em);
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 2.75rem;
}
.select-wrapper select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 2.75rem;
}

textarea {
  padding: 0.75rem 1rem;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2rem;
  opacity: 0;
  width: 1rem;
  z-index: -1;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 300;
  margin: 0 0 0.5rem 0;
  padding-left: 2.65rem;
  padding-right: 0.75rem;
  position: relative;
}
input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -webkit-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -ms-transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  border-radius: 4px;
  border: solid 1px #ffffff;
  content: "";
  display: inline-block;
  height: 1.65rem;
  left: 0;
  line-height: calc(1.58125rem + 0em);
  position: absolute;
  text-align: center;
  top: -0.125rem;
  width: 1.65rem;
}
input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #1b1f22;
  content: "\f00c";
}
input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  background: rgba(255, 255, 255, 0.075);
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}

input[type="checkbox"] + label:before {
  border-radius: 4px;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

.formerize-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

/* Box */
.box {
  border-radius: 4px;
  border: solid 1px #ffffff;
  margin-bottom: 2rem;
  padding: 1.5em;
}
.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}
.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

/* Icon */
.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}
.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}
.icon > .label {
  display: none;
}

/* Image */
.image {
  border-radius: 4px;
  border: 0;
  display: inline-block;
  position: relative;
}
.image:before {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  background-image: url("../images/overlay.png");
  background-color: rgba(19, 21, 25, 0.5);
  border-radius: 4px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
}
.image img {
  border-radius: 4px;
  display: block;
}
.image.left,
.image.right {
  max-width: 40%;
}
.image.left img,
.image.right img {
  width: 100%;
}
.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}
.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}
.image.fit {
  display: block;
  margin: 0 0 2rem 0;
  width: 100%;
}
.image.fit img {
  width: 100%;
}
.image.main {
  display: block;
  margin: 2.5rem 0;
  width: 100%;
}
.image.main img {
  width: 100%;
}
@media screen and (max-width: 736px) {
  .image.main {
    margin: 2rem 0;
  }
}
@media screen and (max-width: 480px) {
  .image.main {
    margin: 1.5rem 0;
  }
}

/* List */
ol {
  list-style: decimal;
  margin: 0 0 2rem 0;
  padding-left: 1.25em;
}
ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2rem 0;
  padding-left: 1em;
}
ul li {
  padding-left: 0.5em;
}
ul.alt {
  list-style: none;
  padding-left: 0;
}
ul.alt li {
  border-top: solid 1px #ffffff;
  padding: 0.5em 0;
}
ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}
ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.icons li {
  display: inline-block;
  padding: 0 0.75em 0 0;
}
ul.icons li:last-child {
  padding-right: 0;
}
ul.icons li a {
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px #ffffff;
  display: inline-block;
  height: 2.25rem;
  line-height: 2.25rem;
  text-align: center;
  width: 2.25rem;
}
ul.icons li a:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
ul.icons li a:active {
  background-color: rgba(255, 255, 255, 0.175);
}
ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}
ul.actions li {
  display: inline-block;
  padding: 0 1rem 0 0;
  vertical-align: middle;
}
ul.actions li:last-child {
  padding-right: 0;
}
ul.actions.small li {
  padding: 0 0.5rem 0 0;
}
ul.actions.vertical li {
  display: block;
  padding: 1rem 0 0 0;
}
ul.actions.vertical li:first-child {
  padding-top: 0;
}
ul.actions.vertical li > * {
  margin-bottom: 0;
}
ul.actions.vertical.small li {
  padding: 0.5rem 0 0 0;
}
ul.actions.vertical.small li:first-child {
  padding-top: 0;
}
ul.actions.fit {
  display: table;
  margin-left: -1rem;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1rem);
}
ul.actions.fit li {
  display: table-cell;
  padding: 0 0 0 1rem;
}
ul.actions.fit li > * {
  margin-bottom: 0;
}
ul.actions.fit.small {
  margin-left: -0.5rem;
  width: calc(100% + 0.5rem);
}
ul.actions.fit.small li {
  padding: 0 0 0 0.5rem;
}
@media screen and (max-width: 480px) {
  ul.actions {
    margin: 0 0 2rem 0;
  }
  ul.actions li {
    padding: 1rem 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }
  ul.actions li:first-child {
    padding-top: 0;
  }
  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }
  ul.actions li > *.icon:before {
    margin-left: -2em;
  }
  ul.actions.small li {
    padding: 0.5rem 0 0 0;
  }
  ul.actions.small li:first-child {
    padding-top: 0;
  }
}

dl {
  margin: 0 0 2rem 0;
}
dl dt {
  display: block;
  font-weight: 600;
  margin: 0 0 1rem 0;
}
dl dd {
  margin-left: 2rem;
}

/* Table */
.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2rem 0;
  width: 100%;
}
table tbody tr {
  border: solid 1px #ffffff;
  border-left: 0;
  border-right: 0;
}
table tbody tr:nth-child(2n + 1) {
  background-color: rgba(255, 255, 255, 0.075);
}
table td {
  padding: 0.75em 0.75em;
}
table th {
  color: #ffffff;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}
table thead {
  border-bottom: solid 2px #ffffff;
}
table tfoot {
  border-top: solid 2px #ffffff;
}
table.alt {
  border-collapse: separate;
}
table.alt tbody tr td {
  border: solid 1px #ffffff;
  border-left-width: 0;
  border-top-width: 0;
}
table.alt tbody tr td:first-child {
  border-left-width: 1px;
}
table.alt tbody tr:first-child td {
  border-top-width: 1px;
}
table.alt thead {
  border-bottom: 0;
}
table.alt tfoot {
  border-top: 0;
}

/* Button */
input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 300;
  height: 2.75rem;
  letter-spacing: 0.2rem;
  line-height: 2.75rem;
  outline: 0;
  padding: 0 1.25rem 0 1.35rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
  background-color: rgba(255, 255, 255, 0.175);
}
input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}
input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1rem 0;
  width: 100%;
}
input[type="submit"].special,
input[type="reset"].special,
input[type="button"].special,
button.special,
.button.special {
  background-color: #ffffff;
  color: #1b1f22 !important;
  font-weight: 600;
}
input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  cursor: default;
  opacity: 0.25;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  line-height: calc(2.75rem - 2px);
}

@keyframes slideIn {
  0% {
    left: 100px;
    opacity: 0;
  }

  10% {
    left: 0;
    opacity: 1;
  }

  90% {
    left: 0;
    opacity: 1;
  }

  100% {
    left: -100px;
    opacity: 0;
  }
}

/* BG */
#bg {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
#bg:before,
#bg:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#bg:before {
  -moz-transition: background-color 2.5s ease-in-out;
  -webkit-transition: background-color 2.5s ease-in-out;
  -ms-transition: background-color 2.5s ease-in-out;
  transition: background-color 2.5s ease-in-out;
  -moz-transition-delay: 0.75s;
  -webkit-transition-delay: 0.75s;
  -ms-transition-delay: 0.75s;
  transition-delay: 0.75s;
  background-image: linear-gradient(
      to top,
      rgba(19, 21, 25, 0.5),
      rgba(19, 21, 25, 0.5)
    ),
    url("../images/overlay.png");
  background-size: auto, 256px 256px;
  background-position: center, center;
  background-repeat: no-repeat, repeat;
  z-index: 2;
}
#bg:after {
  -moz-transform: scale(1.125);
  -webkit-transform: scale(1.125);
  -ms-transform: scale(1.125);
  transform: scale(1.125);
  -moz-transition: -moz-transform 0.325s ease-in-out,
    -moz-filter 0.325s ease-in-out;
  -webkit-transition: -webkit-transform 0.325s ease-in-out,
    -webkit-filter 0.325s ease-in-out;
  -ms-transition: -ms-transform 0.325s ease-in-out,
    -ms-filter 0.325s ease-in-out;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out;
  background-image: url("../images/lesotho-pass.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
body.is-article-visible #bg:after {
  -moz-transform: scale(1.0825);
  -webkit-transform: scale(1.0825);
  -ms-transform: scale(1.0825);
  transform: scale(1.0825);
  -moz-filter: blur(0.2rem);
  -webkit-filter: blur(0.2rem);
  -ms-filter: blur(0.2rem);
  filter: blur(0.2rem);
}
body.is-loading #bg:before {
  background-color: #000000;
}

/* Wrapper */
#wrapper {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 4rem 2rem;
  z-index: 3;
}
#wrapper:before {
  content: "";
  display: block;
}
@media screen and (max-width: 1680px) {
  #wrapper {
    padding: 3rem 2rem;
  }
}
@media screen and (max-width: 736px) {
  #wrapper {
    padding: 2rem 1rem;
  }
}
@media screen and (max-width: 480px) {
  #wrapper {
    padding: 1rem;
  }
}

/* Header */
#header {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-transition: -moz-transform 0.325s ease-in-out,
    -moz-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  -webkit-transition: -webkit-transform 0.325s ease-in-out,
    -webkit-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  -ms-transition: -ms-transform 0.325s ease-in-out,
    -ms-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  background-image: -moz-radial-gradient(
    rgba(0, 0, 0, 0.25) 25%,
    transparent 55%
  );
  background-image: -webkit-radial-gradient(
    rgba(0, 0, 0, 0.25) 25%,
    transparent 55%
  );
  background-image: -ms-radial-gradient(
    rgba(0, 0, 0, 0.25) 25%,
    transparent 55%
  );
  background-image: radial-gradient(rgba(0, 0, 0, 0.25) 25%, transparent 55%);
  max-width: 100%;
  text-align: center;
}
#header > * {
  -moz-transition: opacity 0.325s ease-in-out;
  -webkit-transition: opacity 0.325s ease-in-out;
  -ms-transition: opacity 0.325s ease-in-out;
  transition: opacity 0.325s ease-in-out;
  position: relative;
  margin-top: 3.5rem;
}
#header > *:before {
  content: "";
  display: block;
  position: absolute;
  top: calc(-3.5rem - 1px);
  left: calc(50% - 1px);
  width: 1px;
  height: calc(3.5rem + 1px);
}
#header > :first-child {
  margin-top: 0;
}
#header > :first-child:before {
  display: none;
}

#header .content {
  border-style: solid;
  border-color: #ffffff;
  border-top-width: 1px;
  border-bottom-width: 1px;
  max-width: 100%;
}
#header .content .inner {
  -moz-transition: max-height 0.75s ease, padding 0.75s ease,
    opacity 0.325s ease-in-out;
  -webkit-transition: max-height 0.75s ease, padding 0.75s ease,
    opacity 0.325s ease-in-out;
  -ms-transition: max-height 0.75s ease, padding 0.75s ease,
    opacity 0.325s ease-in-out;
  transition: max-height 0.75s ease, padding 0.75s ease,
    opacity 0.325s ease-in-out;
  -moz-transition-delay: 0.25s;
  -webkit-transition-delay: 0.25s;
  -ms-transition-delay: 0.25s;
  transition-delay: 0.25s;
  padding: 3rem 2rem;
  max-height: 40rem;
  overflow: hidden;
}
#header .content .inner > :last-child {
  margin-bottom: 0;
}
#header .content p {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;
  animation: slideIn 5s ease-in-out infinite;
  line-height: 2;
}
#header nav ul {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin-bottom: 0;
  list-style: none;
  padding-left: 0;
  border: solid 1px #ffffff;
  border-radius: 4px;
}
#header nav ul li {
  padding-left: 0;
  border-left: solid 1px #ffffff;
}
#header nav ul li:first-child {
  border-left: 0;
}
#header nav ul li a {
  display: block;
  min-width: 7.5rem;
  height: 2.75rem;
  line-height: 2.75rem;
  padding: 0 1.25rem 0 1.45rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  border-bottom: 0;
}
#header nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
#header nav ul li a:active {
  background-color: rgba(255, 255, 255, 0.175);
}
#header nav.use-middle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  width: 1px;
  height: 100%;
  background: #ffffff;
}
#header nav.use-middle ul li.is-middle {
  border-left: 0;
}
body.is-article-visible #header {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  -moz-filter: blur(0.1rem);
  -webkit-filter: blur(0.1rem);
  -ms-filter: blur(0.1rem);
  filter: blur(0.1rem);
  opacity: 0;
}
body.is-loading #header {
  -moz-filter: blur(0.125rem);
  -webkit-filter: blur(0.125rem);
  -ms-filter: blur(0.125rem);
  filter: blur(0.125rem);
}
body.is-loading #header > * {
  opacity: 0;
}
body.is-loading #header .content .inner {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
@media screen and (max-width: 980px) {
  #header .content p br {
    display: none;
  }
}
@media screen and (max-width: 736px) {
  #header > * {
    margin-top: 2rem;
  }
  #header > *:before {
    top: calc(-2rem - 1px);
    height: calc(2rem + 1px);
  }
  #header .logo {
    width: 4.75rem;
    height: 4.75rem;
    line-height: 4.75rem;
  }
  #header .logo .icon:before {
    font-size: 1.75rem;
  }
  #header .content .inner {
    padding: 2.5rem 1rem;
  }
  #header .content p {
    line-height: 1.875;
  }
}
@media screen and (max-width: 480px) {
  #header {
    padding: 1.5rem 0;
  }
  #header .content .inner {
    padding: 2.5rem 0;
  }
  #header nav ul {
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 10rem;
    max-width: 100%;
  }
  #header nav ul li {
    border-left: 0;
    border-top: solid 1px #ffffff;
  }
  #header nav ul li:first-child {
    border-top: 0;
  }
  #header nav ul li a {
    height: 3rem;
    line-height: 3rem;
    min-width: 0;
    width: 100%;
  }
  #header nav.use-middle:after {
    display: none;
  }
}

/* Main */
#main {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -moz-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  z-index: 3;
}
#main article {
  -moz-transform: translateY(0.25rem);
  -webkit-transform: translateY(0.25rem);
  -ms-transform: translateY(0.25rem);
  transform: translateY(0.25rem);
  -moz-transition: opacity 0.325s ease-in-out, -moz-transform 0.325s ease-in-out;
  -webkit-transition: opacity 0.325s ease-in-out,
    -webkit-transform 0.325s ease-in-out;
  -ms-transition: opacity 0.325s ease-in-out, -ms-transform 0.325s ease-in-out;
  transition: opacity 0.325s ease-in-out, transform 0.325s ease-in-out;
  padding: 4.5rem 2.5rem 1.5rem 2.5rem;
  position: relative;
  width: 40rem;
  max-width: 100%;
  background-color: rgba(27, 31, 34, 0.85);
  border-radius: 4px;
  opacity: 0;
}
#main article.active {
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
#main article .close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  text-indent: 4rem;
  overflow: hidden;
  white-space: nowrap;
}
#main article .close:before {
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  content: "";
  display: block;
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-position: center;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px' viewBox='0 0 20 20' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23ffffff%3B stroke-width: 1%3B %7D%3C/style%3E%3Cline x1='2' y1='2' x2='18' y2='18' /%3E%3Cline x1='18' y1='2' x2='2' y2='18' /%3E%3C/svg%3E");
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
#main article .close:hover:before {
  background-color: rgba(255, 255, 255, 0.075);
}
#main article .close:active:before {
  background-color: rgba(255, 255, 255, 0.175);
}
@media screen and (max-width: 736px) {
  #main article {
    padding: 3.5rem 2rem 0.5rem 2rem;
  }
  #main article .close:before {
    top: 0.875rem;
    left: 0.875rem;
    width: 2.25rem;
    height: 2.25rem;
    background-size: 14px 14px;
  }
}
@media screen and (max-width: 480px) {
  #main article {
    padding: 3rem 1.5rem 0.5rem 1.5rem;
  }
}

/* Footer */
#footer {
  -moz-transition: -moz-transform 0.325s ease-in-out,
    -moz-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  -webkit-transition: -webkit-transform 0.325s ease-in-out,
    -webkit-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  -ms-transition: -ms-transform 0.325s ease-in-out,
    -ms-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
  transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out,
    opacity 0.325s ease-in-out;
  width: 100%;
  max-width: 100%;
  margin-top: 2rem;
  text-align: center;
}
#footer .copyright {
  letter-spacing: 0.2rem;
  font-size: 0.6rem;
  opacity: 0.75;
  margin-bottom: 0;
  text-transform: uppercase;
}
body.is-article-visible #footer {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  -moz-filter: blur(0.1rem);
  -webkit-filter: blur(0.1rem);
  -ms-filter: blur(0.1rem);
  filter: blur(0.1rem);
  opacity: 0;
}
body.is-loading #footer {
  opacity: 0;
}

/*# sourceMappingURL=main.css.map */
