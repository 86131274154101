/* Confusing names for now */
.page-header {
  text-align: center;
  height: 50px;
  margin: 50px;
}

@media screen and (max-width: 420px) {
  .page-header {
    position: relative;
    margin-top: 0px;
    top: 25px;
  }
}

.close-button {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 24px;
  z-index: 10;
}

@media screen and (max-width: 420px) {
  .close-button {
    width: 24px;
    top: 30px;
    right: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }
}

#header .logo {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  /* border: solid 1px #ffffff; */
  /* trying to get rid of the border line aliasing */
  /* box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit; */
  box-shadow: 0 0 1px 0px white inset, 0 0 1px 0px white;
  border-radius: 100%;
}
#header .logo .icon:before {
  font-size: 2rem;
}

.text-footer {
  position: fixed;
  bottom: 0px;
  color: rgba(255, 255, 255, 0.3);
  display: block;
  font-style: oblique;
  text-align: center;
  width: 100%;
}

.center-screen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For Page Transitions per react-router animation guide */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1s ease-in;
}

.view-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.travel-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.travel {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  max-width: 100%; /* 1240px */
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}

.travel .world-map {
  width: 90%;
}

@media (max-width: 500px) {
  .travel .world-map {
    max-width: 100%;
  }
}

.travel .detail {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  flex-grow: 1;
  margin: 10px;
  min-width: 200px;
  min-height: 200px;
  max-width: 400px;
}

.travel .detail .header {
  background: white;
  color: black;
  text-align: center;
  border-radius: 10px 10px 0 0;
  font-size: 1.5rem;
}

.travel .detail .content {
  margin: 10px;
}

.travel .detail .content img {
  max-width: 80%;
  display: block;
  margin: 5px auto;
}

.travel .world-map .legend {
  position: relative;
  left: 0;
  top: 0;
  margin: 10px;
}

.travel .world-map .legend-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  /* border: 1px solid gray; */
  margin: 0 5px;
}

.travel .world-map .legend-text {
  color: gray;
}

/* PORTFOLIO */

.cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  align-items: stretch;
  justify-content: center;
}

.card {
  width: 400px;
  /* background: #222; */
  /* background-image: linear-gradient(to bottom, #222, #333); */
  background-image: linear-gradient(
    to bottom,
    rgba(32, 32, 32, 1),
    rgba(48, 48, 48, 0.7)
  );
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
}

.card .header {
  width: 100%;
  border-bottom: 2px solid white;
  text-align: center;
}

.card .content {
}

.card .content h5 {
  text-align: center;
}

.card .content > p {
  color: silver;
  text-align: center;
  margin: 0 !important;
}

.card .content > .divider {
  height: 0px;
  width: 10%;
  margin: 10px auto;
  border: 0.5px solid gray;
}

.icon-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.vertical {
  flex-direction: column;
}

.labeled-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  text-align: center;
}

.labeled-icon i {
  font-size: 36px;
  line-height: 50px;
}

.labeled-icon i::before {
  /* This centers wide fontello logos */
  display: inline !important;
}

.labeled-icon img {
  max-width: 75px;
}

.labeled-icon p {
  /* font-style: oblique; */
  color: silver;
  font-size: 10pt;
}

.icon-group-vertical {
  display: flex;
  flex-direction: column;
}

.labeled-icon-vertical {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.labeled-icon-vertical i {
  font-size: 36px;
  line-height: 50px;
  width: 50px;
}

.labeled-icon-vertical img {
  width: 50px;
}

.labeled-icon-vertical div {
  padding-left: 20px;
  font-style: oblique;
  color: gray;
  font-size: 12pt;
}

/* Timeline (except that still in timeline.css */

.filter-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

@media screen and (max-width: 380px) {
  .filter-bar {
    font-size: 0.8rem;
  }
}

/* .filter-bar::before {
  content: 'SHOW: ';
} */

.filter-bar a {
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 10px;
  border: 2px solid white;
  cursor: pointer;
}

.filter-bar a:hover,
a.active {
  background: rgba(255, 255, 255, 0.2);
  border-bottom-color: inherit !important; /* To override a weird setting from font-awesome css */
}

/* .filter-bar  {
  background: #ddd;
} */

.filter-bar a.active:hover {
  background: none;
  color: white;
}
